.article {
  font-size: 27px;
  line-height: 36px;
  letter-spacing: -.25px;
  transform: translateZ(0);

  .js-paragraph{
    position: relative;
    transform: translateZ(0);

    &:after {
      position: absolute;
      width: 50px;
      height: 50px;
      background-repeat: no-repeat;
      background-position: center;
      transform: translate(-15px,-7px) translateZ(0);
      content: '';
    }

    &.is-hide{
      opacity: 0;
    }

    &.is-animate:after{
      background-image: url(../img/icon-lightning.svg);
      will-change: opacity;
      animation: blink .5s infinite;
    }

    &.is-thinking:after{
      margin-top: 0;
      width: 50px;
      height: 50px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      animation: thinks .7s infinite;
      will-change: background-image;
    }

    &.is-waiting:after{
      width: 27px;
      height: 27px;
      margin-top: 4px;
      transform: unset;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      animation: waiting .7s infinite;
      will-change: background-image;
    }

    i{
      position: relative;
      padding-left: 38px;
      font-style: normal;
      color: #fc2b2b;

      &:before {
        position: absolute;
        left: 6px;
        width: 50px;
        height: 50px;
        margin-top: 0;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        animation: thinks .7s infinite;
        will-change: background-image;
        transform: translate(-15px,-7px) translateZ(0);
        content: '';
      }

    }

  }

  p{
    transition: opacity .3s ease;

    svg{
      display: inline-block;
      vertical-align: middle;
      width: 19px;
      margin-left: -10px;
    }

    a{
      color: #fc2b2b;
      font-size: 40px;
    }

  }

  h2, h3{
    margin-bottom: 28px;
    font-size: 32px;
    line-height: 34px;
    text-transform: uppercase;

    span{
      display: block;
      color: transparent;
      -webkit-text-stroke: .5px #fc2b2b;
    }

  }

}
@media (min-width: $phone-landscape) {
  .article {

  }
}
@media (min-width: $tablet-portrait) {
  .article {
    text-align: center;

    p {

      &:after {
        width: 42px;
        height: 42px;
      }

      svg {
        width: 25px;
      }

    }

  }
}
@media (min-width: $tablet-landscape) {
  .article {
    font-size: 30px;
    line-height: 35px;

    .js-paragraph{

      &:after{
          transform: translate(-14px, -1px);
      }

    }

  }
}
@media (min-width: $desktop) {
  .article {

  }
}

@media (min-width: 1920px) {
  .article {
    font-size: .3em;
    line-height: 1.166em;
    letter-spacing: -.0083em;

    .js-paragraph{

      &:after {
        width: 1.666em;
        height: 1.666em;
        transform: translate(-.466em, -.033em);
      }

      &.is-thinking:after{
        width: 1.666em;
        height: 1.666em;
      }

      &.is-waiting:after{
        width: .9em;
        height: .9em;
        margin-top: .133em;
      }

      i{
        padding-left: 1.266em;

        &:before {
          left: .2em;
          width: 1.666em;
          height: 1.666em;
          transform: translate(-.5em,-.23em) translateZ(0);
        }

      }

    }

    p{

      &:after {
        width: 1.4em;
        height: 1.4em;
      }

      svg{
        width: .833em;
        margin-left: -.333em;
      }

      a{
        font-size: 1.333em;
      }

    }

    h2, h3{
      margin-bottom: .875em;
      font-size: 1.066em;
      line-height: 1.0625em;

      span{
        -webkit-text-stroke: .0156em #fc2b2b;
      }

    }

  }
}

.is-dark-theme,
.is-black-theme,
.is-black-theme-1{
  color: #d1d2d2;
}
