.content{
  position: absolute;
  top: 0;
  left: 0;
  right: -17px;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;
  padding: 0 33px 0 16px;
  color: #d1d2d2;
  @include FfiraCode-Medium;
  transition: opacity .3s ease, visibility .3s ease;
  z-index: 2;

  &__wrap{
    min-height: 70vh;
    padding-top: 90px;
    text-transform: uppercase;

    &:after{
      content: '';
      display: block;
      height: 36px;
      width: 100%;
    }

    p {

      span {
        white-space: nowrap;

        &:before,
        &:after{
          display: inline-block;
          color: #fd2e2a;
        }

        &:before{
          content: '{ ';
        }

        &:after{
          content: ' }';
        }

      }

      &.content__paragraph-delimiter{
        margin-top: 36px;
      }

    }

    > .control > div{
      margin: 8px 0 5px auto;
    }

  }

  &__checker{
    position: relative;
    height: 0;
    font-size: 22px;
    overflow: hidden;
    transition: height .3s ease;

    @media (max-width: 360px) {
      font-size: 20px;
    }

    li{
      position: relative;
      height: 36px;
      width: 50%;
      margin-bottom: 35px;
      text-align: center;

      span{
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, 0);
        overflow: hidden;
        display: inline-flex;
        justify-content: center;
        padding: 0 27px;
        //word-break: break-all;
        //$randomNumber: random(5)/5 + .3;

        &:before,
        &:after{
          position: absolute;
          top: 0;
          width: 36px;
          height: 36px;
          background-repeat: no-repeat;
          background-position: center;
          background-size: auto 27px;
          background-image: url(../img/char-001.svg);
          content: '';
        }

        &:before{
          left: 0;
          //animation: blink infinite;
          //animation-duration: $randomNumber + s;
        }

        &:after{
          right: 0;
          transform: matrix(-1,0,0,1,0,0);
          //animation: blink-2 infinite;
          //animation-duration: $randomNumber + s;
        }

      }

      &:nth-child(1) span{
        &:before,
        &:after{
          //background-image: url(../img/char-001.svg);
          animation-delay: .1s;
        }
      }
      &:nth-child(2) span{
        &:before,
        &:after {
          //background-image: url(../img/char-002.svg);
        }
      }
      &:nth-child(3) span{
        &:before,
        &:after {
          //background-image: url(../img/char-003.svg);
        }
      }
      &:nth-child(4) span{
        &:before,
        &:after{
          //background-image: url(../img/char-004.svg);
          animation-delay: .1s;
        }
      }
      &:nth-child(5) span{
        &:before,
        &:after{
          //background-image: url(../img/char-005.svg);
        }
      }
      &:nth-child(6) span{
        &:before,
        &:after{
          //background-image: url(../img/char-006.svg);
        }
        &:after{
          //top: calc( 50% - 13px);
        }
      }

    }

    &-wrap{
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-start;
      padding-top: 55px;
    }

  }

  &__question{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    position: relative;
    height: 0;
    transition: all .3s ease;
    margin: 0 -20px;
    overflow: hidden;

    &-layout {
      width: 100%;
    }

    &-wrap{
      padding: 30px 0;

      > div{
        padding: 40px 20px;
        color: #000;
        background: url(../img/img-background.jpg) $background-color;

        p{
          transition: opacity .3s ease, transform .3s ease;
          transition-delay: .3s;
        }

      }

    }

    &-btn{
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 85%;
      max-width: 339px;
      max-height: 125px;
      margin: 20px auto 0;
      text-align: center;

      li{
        position: relative;
        height: 140px;
        width: 35%;
        white-space: nowrap;
        transition: opacity .3s ease, transform .3s ease;

        > span, > a{
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          top: 0;
          left: 50%;
          margin-left: -70px;
          width: 140px;
          height: 140px;
          color: #000;

          &:after{
            content: '';
            top: 50%;
            left: 50%;
            position: absolute;
            transform: translate(-50%,-50%);
            pointer-events: none;
          }

          span{
            position: relative;
            z-index: 1;
          }

        }

        &:nth-child(1){
          justify-content: flex-end;
          transition-delay: .5s;

          > span, > a{

            &:after{
              width: 150px;
              height: 139px;
              background: url(../img/img-btn-border-2.png) no-repeat center;
              background-size: contain;
              transition: background .3s ease;
            }

            &.is-active:after{
              background-image: url(../img/img-btn-border-3.png);
            }

          }

        }
        &:nth-child(2){
          justify-content: center;
          transition-delay: .7s;

          > span, > a{
            transition: color .3s ease;

            &:after{
              width: inherit;
              height: inherit;
              border: 2px solid #202124;
              border-radius: 50%;
              transition: background-color .3s ease;
            }

            &.is-active{
              color: #fff;

              &:after{
                background-color: #202124;
              }

            }

          }

        }

      }

    }

    &.is-hide{

      .content__question-wrap > div > p{
        transform: translateY(40px);
        opacity: 0;
      }

      .content__question-btn > li{
        transform: translateY(40px);
        opacity: 0;
      }

      .control{
        opacity: 0;
      }

    }

    .control{
      position: absolute;
      top: -33px;
      right: 10px;
      transition: opacity .3s ease .3s;
    }

  }

  &.is-hide{
    position: absolute;
    opacity: 0;
    visibility: hidden;
  }

  &.is-black-theme{
    background: url(../img/img-background-black.jpg) #27282b;
  }

}
@media (min-width: $phone-landscape) {
  .content{

  }
}
@media (min-width: $tablet-portrait) {
  .content{

  }
}
@media (min-width: $tablet-landscape) {
  .content{

    &__checker {
      max-width: 970px;
      margin: 0 auto;
      font-size: inherit;

      span {
        color: rgba(209,210,210,1);
        transition: color .3s ease;
        cursor: pointer;

        &:hover{
          color: rgba(209,210,210,.5);
        }

        &:before,
        &:after{
          top: -1px;
        }

      }

    }

    &__wrap{

      > p {
        max-width: 970px;
        margin: 0 auto;
      }

    }

    &__question{

      &-btn{
        max-width: 429px;
        margin: 50px auto 20px;

        li{

          > span, > a{
            width: 170px;
            height: 170px;
            cursor: pointer;

            &:after{
              transition: all .3s ease;
            }

          }

          &:nth-child(1){
            > span, > a {
              &:after {
                top: 48%;
                left: 45%;
                width: 207px;
                height: 177px;
              }

              &:before{
                background-image: url(../img/img-btn-border-3-hover.png)
              }

              &:hover:after{
                background-image: url(../img/img-btn-border-3-hover.png);
              }

            }
          }
          &:nth-child(2){
            > span, > a {
              &:after {
                border-width: 3px;
              }

              &:hover:after{
                border-color: #737373;
                background-color: #737373;
                color: #f54c4c;
              }

            }
          }

        }

      }

    }

  }
}
@media (min-width: $desktop) {
  .content {

  }
}
@media (min-width: 1920px) {
  .content {
    right: -.17em;
    padding: 0 .33em 0 .16em;

    &__wrap{
      padding-top: 3em;

      > p {
        max-width: 32.33em;
      }

    }

    &__checker {
      max-width: 32.33em;

      li{
        height: 1.166em;
        margin-bottom: 1.166em;

        span{
          padding: 0 1.2em;

          &:before,
          &:after{
            width: 1.2em;
            height: 1.2em;
            background-size: auto 1.2em;
          }

        }

      }

    }

  }
}