.logo{
  display: block;
  position: relative;
  top: -8px;
  max-width: 126px;
  color: #34363a;
  @include Miratrix-Normal;
  font-size: 20px;
  text-transform: uppercase;
  cursor: pointer;
  transition: color .3s ease, opacity .3s ease;

  img{
    position: absolute;
    top: 50%;
    left: calc( 50% + 25px);
    display: block;
    width: 100%;
    max-width: 48px;
  }

}
.site__header--shared{

}
@media (min-width: $phone-landscape) {
  .logo{

  }
}
@media (min-width: $tablet-portrait) {
  .logo{

  }
}
@media (min-width: $tablet-landscape) {
  .logo{
    font-size: 24px;

    img{
      max-width: 52px;
      left: calc(50% + 33px);
    }

  }
}
@media (min-width: $desktop) {
  .logo {
    font-size: 29px;

    img{
      max-width: 66px;
      left: calc( 50% + 42px);
    }

  }
}
@media (min-width: 1920px) {
  .logo {
    top: -.08em;
    max-width: 1.26em;
    font-size: .29em;

    img{
      max-width: 2.275em;
      left: calc( 50% + 1.448em);
    }

  }
}

.is-black-theme{

  .logo{
    color: #fff;
  }

}
