.disclaimer{
  display: none;
  max-width: 550px;
  height: auto!important;
  margin-top: auto;
  padding: 30px 8px 30px;
  font-size: 11px;
  letter-spacing: 2.1px;
  text-align: center;

  img{
    display: block;
    width: 100%;
    margin-bottom: 11px;
  }

  a{
    display: inline-block;
    color: #4a4d4f;
    text-decoration: underline;
    text-transform: uppercase;
  }

  .id-mobile{

  }

  .id-desktop{
    display: none;
  }

}
@media (min-width: $phone-landscape) {
  .disclaimer{

  }
}
@media (min-width: $tablet-portrait) {
  .disclaimer{

  }
}
@media (min-width: $tablet-landscape) {
  .disclaimer{

  }
}
@media (min-width: $desktop) {
  .disclaimer{

  }
}
@media (min-width: 1920px) {
  .disclaimer{
    padding: 0 .727em 2.727em;
    font-size: .11em;
    letter-spacing: .19em;

    img{
      margin-bottom: 1em;
    }

  }
}