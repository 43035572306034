@-webkit-keyframes blink {
	0%, 30% { opacity: 1; transform: translateZ(0) }
	70%, 100% { opacity: 0; transform: translateZ(0) }
}
@keyframes blink {
	0%, 30% { opacity: 1; transform: translateZ(0) }
	70%, 100% { opacity: 0; transform: translateZ(0) }
}

@-webkit-keyframes blink-2 {
	0%, 30% { opacity: 1; transform: rotate(180deg) translateZ(0) }
	70%, 100% { opacity: 0; transform: rotate(180deg) translateZ(0) }
}
@keyframes blink-2 {
	0%, 30% { opacity: 1; transform: rotate(180deg) translateZ(0) }
	70%, 100% { opacity: 0; transform: rotate(180deg) translateZ(0) }
}

@-webkit-keyframes rotate {
	0% { transform: rotate(0deg) translateZ(0) }
	100% { transform: rotate(360deg) translateZ(0) }
}
@keyframes rotate {
	0% { transform: rotate(0deg) translateZ(0) }
	100% { transform: rotate(360deg) translateZ(0) }
}

@-webkit-keyframes rotate-a {
	0% { transform: rotate(0deg) translateZ(0) }
	100% { transform: rotate(-360deg) translateZ(0) }
}
@keyframes rotate-a {
	0% { transform: rotate(0deg) translateZ(0) }
	100% { transform: rotate(-360deg) translateZ(0) }
}

@-webkit-keyframes thinks {
	0%, 19%   { background-image: url(../img/icon-boom-red.svg); background-size: 90px 90px; margin: -20px 0 0 -20px; width: 90px; height: 90px; }
	20%, 39%   { background-image: url(../img/icon-boom.svg); background-size: 90px 90px; margin: -20px 0 0 -20px; width: 90px; height: 90px; }
	40%, 59%   { background-image: url(../img/icon-fire.svg); background-size: 50px 50px; margin: 0; width: 50px; height: 50px; }
	60%, 79%   { background-image: url(../img/icon-lightning-red.svg); background-size: 50px 50px; margin: 0; width: 50px; height: 50px; }
	80%, 100%   { background-image: url(../img/icon-drive.svg); background-size: 50px 50px; margin: 0; width: 50px; height: 50px; }
}
@keyframes thinks {
	0%, 19%   { background-image: url(../img/icon-boom-red.svg); background-size: 90px 90px; margin: -20px 0 0 -20px; width: 90px; height: 90px; }
	20%, 39%   { background-image: url(../img/icon-boom.svg); background-size: 90px 90px; margin: -20px 0 0 -20px; width: 90px; height: 90px; }
	40%, 59%   { background-image: url(../img/icon-fire.svg); background-size: 50px 50px; margin: 0; width: 50px; height: 50px; }
	60%, 79%   { background-image: url(../img/icon-lightning-red.svg); background-size: 50px 50px; margin: 0; width: 50px; height: 50px; }
	80%, 100%   { background-image: url(../img/icon-drive.svg); background-size: 50px 50px; margin: 0; width: 50px; height: 50px; }
}

@-webkit-keyframes waiting {
	0%, 24%   { background-image: url(../img/char-001.svg); }
	25%, 49%   { background-image: url(../img/char-002.svg); }
	50%, 74%   { background-image: url(../img/char-003.svg); }
	75%, 100%	{ background-image: url(../img/char-004.svg); }
}
@keyframes waiting {
	0%, 24%   { background-image: url(../img/char-001.svg); }
	25%, 49%   { background-image: url(../img/char-002.svg); }
	50%, 74%   { background-image: url(../img/char-003.svg); }
	75%, 100%	{ background-image: url(../img/char-004.svg); }
}

@-webkit-keyframes jiggle {
	0%, 100%   { transform: translateY(0); }
	50% { transform: translateY(10px); }
}
@keyframes jiggle {
	0%, 100%   { transform: translateY(0); }
	50% { transform: translateY(10px); }
}

@keyframes scale-easeOutElastic {
	0% {
		opacity: 0;
		transform: translate(30px, -30px) translateZ(0);
	}
	40% {
		opacity: 1;
	}
	60% {
		transform: translate(-10px, 10px) translateZ(0);
	}
	100% {
		transform: translate(0, 0) translateZ(0);
	}
}

@media (min-width: 1920px) {
	@-webkit-keyframes thinks {
		0%, 19% {
			background-image: url(../img/icon-boom-red.svg);
			background-size: 3em 3em;
			margin: -.66em 0 0 -.66em;
			width: 3em;
			height: 3em;
		}
		20%, 39% {
			background-image: url(../img/icon-boom.svg);
			background-size: 3em 3em;
			margin: -.66em 0 0 -.66em;
			width: 3em;
			height: 3em;
		}
		40%, 59% {
			background-image: url(../img/icon-fire.svg);
			background-size: 1.66em 1.66em;
			margin: 0;
			width: 1.66em;
			height: 1.66em;
		}
		60%, 79% {
			background-image: url(../img/icon-lightning-red.svg);
			background-size: 1.66em 1.66em;
			margin: 0;
			width: 1.66em;
			height: 1.66em;
		}
		80%, 100% {
			background-image: url(../img/icon-drive.svg);
			background-size: 1.66em 1.66em;
			margin: 0;
			width: 1.66em;
			height: 1.66em;
		}
	}
	@keyframes thinks {
		0%, 19% {
			background-image: url(../img/icon-boom-red.svg);
			background-size: 3em 3em;
			margin: -.66em 0 0 -.66em;
			width: 3em;
			height: 3em;
		}
		20%, 39% {
			background-image: url(../img/icon-boom.svg);
			background-size: 3em 3em;
			margin: -.66em 0 0 -.66em;
			width: 3em;
			height: 3em;
		}
		40%, 59% {
			background-image: url(../img/icon-fire.svg);
			background-size: 1.66em 1.66em;
			margin: 0;
			width: 1.66em;
			height: 1.66em;
		}
		60%, 79% {
			background-image: url(../img/icon-lightning-red.svg);
			background-size: 1.66em 1.66em;
			margin: 0;
			width: 1.66em;
			height: 1.66em;
		}
		80%, 100% {
			background-image: url(../img/icon-drive.svg);
			background-size: 1.66em 1.66em;
			margin: 0;
			width: 1.66em;
			height: 1.66em;
		}
	}
}