.promo{
  @include section;
  @include is-hidden;

  h2{
    position: absolute;
    top: 50%;
    left: 50%;
    color: #202124;
    font-size: 90px;
    @include Miratrix-Normal;
    transform: translate(-50%, -50%);
  }

  &__frame{
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    @include Miratrix-Normal;
    transform: rotate(30deg);
  }

  &__screen{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-top: 40vh;

    &:nth-child(1){
      left: 130px;
      transition: left 4s ease;
    }
    &:nth-child(2){
      left: -120px;
      transition: left 4.5s ease .5s;
    }
    &:nth-child(3){
      left: 150px;
      transition: left 3.5s ease .5s;
    }

  }

  .fold {
    overflow: hidden;
    width: 90vw;
    height: 80vh;

    &-top {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 100%;
      transform-origin: bottom center;
      transform: rotateX(-115deg);

      .fold-align {
        transform: translateY(100%);
      }

      .fold-content {
        position: relative;
        top: -371px;
      }

    }

    &-bottom {
      display: none;
      position: absolute;
      right: 0;
      left: 0;
      top: 100%;
      transform-origin: top center;
      transform: rotateX(120deg);

      .fold-align {
        transform: translateY(-100%);
      }

    }

    &-center {
      height: 100vh;
    }

    &-align {
      width: 100%;
      height: 100%;
    }

  }

  .wrapper-3d {
    position: relative;
    perspective: 260px;
    transform-style: preserve-3d;
  }

  .animate-text {
    display: block;
    height: 73px;
    text-align: center;
    text-transform: uppercase;
    font-size: 95px;
    line-height: 73px;
    color: transparent;
    -webkit-text-stroke: .5px #fc2b2b;
  }

  &.is-show{

    .promo__screen{
      top: 0;
      left: 0;
    }

  }

}
@media (min-width: $phone-landscape) {
  .promo{

  }
}
@media (min-width: $phone-middle) {
  .promo{

    &__screen{
      padding-top: 30vh;
    }

    .wrapper-3d {
      perspective: 320px;
    }
  }
}
@media (min-width: $tablet-portrait) {
  .promo{

  }
}
@media (min-width: $tablet-landscape) {
  .promo{

    h2{
      font-size: 140px;
    }

    .animate-text {
      height: 109px;
      font-size: 142px;
      line-height: 109px;
      -webkit-text-stroke: .5px #fc2b2b;
    }

    .fold {

      &-top {

        .fold-content {
          top: -328px;
        }

      }
    }
  }
}
@media (min-width: $desktop) {
  .promo{

  }
}
@media (min-width: 1920px) {
  .promo{

    h2{
      font-size: .9em;
    }

    &__screen{

      &:nth-child(1){
        left: 1.3em;
      }
      &:nth-child(2){
        left: -1.2em;
      }
      &:nth-child(3){
        left: 1.5em;
      }

    }

    .fold {

      &-top {
        top: -3.28em;

        .fold-content {
          top: -3.71em;
        }

      }

    }

    .fold {

      &-top {

        .fold-content {
          top: -3.28em;
        }

      }
    }

    .wrapper-3d {
      perspective: 3.2em;
    }

    .animate-text {
      height: 1.09em;
      font-size: 1.42em;
      line-height: 1.09em;
    }

  }
}

