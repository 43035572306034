@mixin Miratrix-Normal() {
  font-family: "Miratrix-Normal", sans-serif;
  font-weight: 400;
  font-style: normal;
}
@mixin IBMPlexMono-Medium() {
  font-family: "IBMPlexMono-Medium", sans-serif;
  font-weight: 400;
  font-style: normal;
}
@mixin FfiraCode-Medium() {
  font-family: "FfiraCode-Medium", sans-serif;
  font-weight: 400;
  font-style: normal;
}

@mixin is-hidden() {
  opacity: 0;
  visibility: hidden;
  transition: opacity .3s ease, visibility .3s ease;
}

@mixin section() {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
}