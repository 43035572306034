.control{

  > div{
    display: flex;
    justify-content: center;
    align-items: stretch;
    position: relative;
    width: 77px;
    height: 77px;
    border: 4px solid transparent;
    z-index: 1;

    &:before{
      content: '';
      position: absolute;
      top: -3px;
      left: -3px;
      width: calc( 100% + 6px );
      height: calc( 100% + 6px );
      background: url(../img/img-btn-loader.png) no-repeat center;
      background-size: cover;
      animation: rotate 1s infinite linear;
      opacity: 0;
      transition: opacity .3s ease;
      z-index: 1;
    }

  }

  &__btn{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;

    svg, img{
      display: block;
      position: relative;
      width: 20px;
    }

  }

  &__main-btn{
    width: 69px;
    height: 69px;
    position: relative;
    background-color: #d1d2d2;
    z-index: 2;
    transition: background-color .3s ease;

    svg, img{
      width: 27px;
    }

  }

  &__circle{
    fill:#fc2b2b;
    transition: fill .3s ease;
  }

  &__elem{
    fill:none;
    stroke:#fc2b2b;
    transition: stroke .3s ease;
  }

  &__list{
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;

    > * {
      position: absolute;
      top: 10px;
      left: 10px;
      width: 48px;
      height: 48px;
      background-color: #fc2b2b;
      transition: top .3s ease, left .3s ease, background-color .3s ease;

      svg{
        fill: #fff;
      }

      &:nth-child(1){
        svg{
          width: 8px;
        }
      }
      &:nth-child(2){
        transition-delay: .1s;

        svg{
          width: 21px;
        }
      }
      &:nth-child(3){
        transition-delay: .2s;

        svg{
          width: 22px;
        }
      }

    }

  }

  &.is-show{

    .control__list{

      > * {

        &:nth-child(1){
          top: 14px;
          left: -60px;
        }
        &:nth-child(2){
          top: -36px;
          left: -41px;
        }
        &:nth-child(3){
          top: -55px;
          left: 10px;
        }

      }

    }

    .control__main-btn{
      background-color: #fc2b2b;
    }

    .control__elem{
      stroke: #fff;
    }

    .control__circle{
      fill: #fff;
    }

  }

  &.is-loading{
    pointer-events: none;

    > div:before{
      opacity: 1;
    }

  }

}
@media (min-width: $phone-landscape) {
  .control{

  }
}
@media (min-width: $tablet-portrait) {
  .control{

  }
}
@media (min-width: $tablet-landscape) {
  .control {
    max-width: 970px;
    margin: 0 auto;

    &__list{

      > *:hover {
        background-color: #F54C4C;
      }

    }

    &.is-show{

      .control__main-btn:hover{
        background-color: #F54C4C;
      }

    }

  }
  .control:hover,
  .sharing-screen__swiper:hover + .control{
    opacity: 1;
  }
}
@media (min-width: $desktop) {
  .control{

  }
}
@media (min-width: 1920px) {
  .control{
    max-width: 9.7em;

    > div{
      width: .77em;
      height: .77em;
      border-width: .04em;

      &:before{
        top: -.03em;
        left: -.03em;
        width: calc( 100% + .06em );
        height: calc( 100% + .06em );
      }

    }

    &__btn{

      svg, img{
        width: .2em;
      }

    }

    &__main-btn{
      width: .69em;
      height: .69em;

      svg, img{
        width: .27em;
      }

    }

    &__list{

      > * {
        top: .1em;
        left: .1em;
        width: .48em;
        height: .48em;

        &:nth-child(1){
          svg{
            width: .08em;
          }
        }
        &:nth-child(2){
          svg{
            width: .21em;
          }
        }
        &:nth-child(3){
          svg{
            width: .22em;
          }
        }

      }

    }

    &.is-show{

      .control__list{

        > * {

          &:nth-child(1){
            top: .14em;
            left: -.6em;
          }
          &:nth-child(2){
            top: -.36em;
            left: -.41em;
          }
          &:nth-child(3){
            top: -.55em;
            left: .1em;
          }

        }

      }

    }

  }
}
