#about-us{
  z-index: 2;
  opacity: 0;
  visibility: hidden;
  transition: opacity .3s ease, visibility .3s ease;

  &.is-active{
    opacity: 1;
    visibility: visible;
  }

}

.about-us {
  min-height: 100vh;
  max-width: 375px;
  margin: 0 auto;
  padding: 60px 17px 34px 17px;

  p span{
    color: #fc2b2b;
  }

  p i{
    display: inline-block;
    position: relative;
    width: 10px;
    height: 22px;
    vertical-align: baseline;

    &:after{
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      transform: translate(-50%, -50%);
      pointer-events: none;
    }

    &.sad:after{
      width: 37px;
      height: 30px;
      margin-top: 9px;
      margin-left: 9px;
      background-image: url(../img/icon-distort-smile.svg)
    }

    &.face:after{
      width: 28px;
      height: 28px;
      margin-top: 6px;
      margin-left: 19px;
      background-image: url(../img/icon-smile.svg)
    }

    &.flash:after{
      width: 21px;
      height: 35px;
      margin-top: 1px;
      margin-left: -8px;
      background-image: url(../img/icon-lightning-red.svg)
    }

    &.flash-transparent:after{
      width: 91px;
      height: 61px;
      margin-top: 8px;
      margin-left: -3px;
      background-image: url(../img/icon-lightning.svg)
    }

    &.fire:after{
      width: 31px;
      height: 42px;
      margin-top: 2px;
      background-image: url(../img/icon-fire-red.svg)
    }

    &.drive:after{
      width: 32px;
      height: 43px;
      margin-top: -1px;
      background-image: url(../img/icon-drive.svg)
    }

    &.boom:after{
      width: 91px;
      height: 75px;
      margin-top: 9px;
      margin-left: 21px;
      background-image: url(../img/icon-boom.svg)
    }

    &.boom-fill:after{
      width: 91px;
      height: 75px;
      margin-top: 9px;
      margin-left: 21px;
      background-image: url(../pic/icon-boom-fill.svg)
    }

  }

  .article{
    font-size: 22.5px;
    //line-height: 36.5px;
  }

  &__wrap1{
    position: relative;
    padding: 42px 21px 54px 0;

    &:before{
      content: '';
      position: absolute;
      right: -41px;
      bottom: 156px;
      width: 122px;
      height: 164px;
      background: url(../img/icon-arrow-down.svg) no-repeat center;
      background-size: contain;
      pointer-events: none;
    }

    p{
      position: relative;

      &:nth-of-type(1){
        text-transform: uppercase;
      }
    }

  }

  &__wrap2{
    position: relative;
    padding: 44px 0 22px;

    p {
      position: relative;

      i.boom:after{
        width: 111px;
        height: 95px;
        margin-top: -51px;
        margin-left: 7px;
        transform: rotate(133deg);
      }

    }

  }

  &__wrap3{
    position: relative;
    padding: 64px 22px 57px 0;

    &:before{
      content: '';
      position: absolute;
      right: 38px;
      top: 177px;
      width: 46px;
      height: 80px;
      background: url(../img/icon-lightning.svg) no-repeat center;
      background-size: contain;
      pointer-events: none;
    }

    &:after{
      content: '';
      position: absolute;
      right: -41px;
      bottom: 246px;
      width: 122px;
      height: 164px;
      background: url(../img/icon-arrow-down.svg) no-repeat center;
      background-size: contain;
      pointer-events: none;
    }

    p {
      position: relative;

      i.boom-fill{
        position: absolute;

        &:after{
          width: 71px;
          height: 65px;
          margin-top: -43px;
          margin-left: -49px;
          transform: rotate(45deg);
        }

      }

    }

  }

  &__wrap4{
    position: relative;
    padding: 40px 25px 17px 0;

    p {
      position: relative;

      i.drive:after{
        margin-top: 12px;
      }

      i.boom-fill:after {
        width: 81px;
        height: 54px;
        margin-top: -21px;
        margin-left: -35px;
        transform: rotate(-25deg);
      }

    }

  }

  &__arrow-top{
    display: flex;
    justify-content: flex-end;
    transition: opacity .3s ease 3s;
    animation: jiggle 1s infinite 3s;

    svg{
      display: block;
      width: 118px;
      margin-left: auto;
      transform: rotate(180deg);

      path{
        fill:$background-color;
        stroke:#fc2b2b;
        stroke-miterlimit:10;
        stroke-width:4px;
      }

    }

  }

  &__bnt-wrap{
    margin-top: 20px;
    padding-right: 10px;

    .btn{
      max-width: 360px;
      margin: 0 auto;
    }

  }

}
@media (min-width: $phone-landscape) {
  .about-us {

  }
}
@media (min-width: $tablet-portrait) {
  .about-us {
    max-width: 1170px;
    padding: 90px 15px 46px;

    &__layout{
      max-width: 650px;
      margin: 0 auto;
    }

  }
}
@media (min-width: $tablet-landscape) {
  .about-us {

    &__arrow-top{

      svg{
        cursor: pointer;

        path{
          transition: fill .3s ease;
        }

        &:hover path{
          fill:#fc2b2b;
        }

      }

    }

  }
}
@media (min-width: $desktop) {
  .about-us {

  }
}
@media (min-width: 1920px) {
  .about-us {
    max-width: 11.7em;
    padding: .9em .15em .46em;

    p i{
      width: 10px;
      height: 22px;

      &.sad:after{
        width: 37px;
        height: 30px;
        margin-top: 9px;
        margin-left: 9px;
      }

      &.face:after{
        width: 28px;
        height: 28px;
        margin-top: 6px;
        margin-left: 19px;
      }

      &.flash:after{
        width: 21px;
        height: 35px;
        margin-top: 1px;
        margin-left: -8px;
      }

      &.flash-transparent:after{
        width: 91px;
        height: 61px;
        margin-top: 8px;
        margin-left: -3px;
      }

      &.fire:after{
        width: 31px;
        height: 42px;
        margin-top: 2px;
      }

      &.drive:after{
        width: 32px;
        height: 43px;
        margin-top: -1px;
      }

      &.boom:after{
        width: 91px;
        height: 75px;
        margin-top: 9px;
        margin-left: 21px;
      }

      &.boom-fill:after{
        width: 91px;
        height: 75px;
        margin-top: 9px;
        margin-left: 21px;
      }

    }

    h2, h3{
      font-size: .855em;
    }

    .article{
      font-size: .225em;
    }

    &__layout {
      max-width: 6.5em;
    }

    &__wrap1{
      padding: 1.86em .7px 1.8px 0;

      &:before{
        right: -1.367empx;
        bottom: 5.2em;
        width: 4.067em;
        height: 5.467em;
      }

    }

    &__wrap2{
      padding: 1.467em 0 .733em;

      p {

        i.boom:after{
          width: 3.7em;
          height: 3.167em;
          margin-top: -1.7em;
          margin-left: .233em;
        }

      }

    }

    &__wrap3{
      padding: 2.133em .733em 1.9em 0;

      &:before{
        right: 1.267em;
        top: 5.9em;
        width: 1.533em;
        height: 2.667em;
      }

      &:after{
        right: -1.367em;
        bottom: 8.2em;
        width: 4.067em;
        height: 5.467em;
      }

      p {

        i.boom-fill{

          &:after{
            width: 2.367em;
            height: 2.167em;
            margin-top: -1.433em;
            margin-left: -1.633em;
          }

        }

      }

    }

    &__wrap4{
      padding: 1.333em .833em .567em 0;

      p {

        i.drive:after{
          margin-top: .4em;
        }

        i.boom-fill:after {
          width: 2.7em;
          height: 1.8em;
          margin-top: -.7em;
          margin-left: -1.167em;
        }

      }

    }

    &__arrow-top{

      svg{
        width: 1.18em;

        path{
          stroke-width:.04em;
        }

      }

    }

    &__bnt-wrap{
      margin-top: .2em;
      padding-right: .1em;

      .btn{
        max-width: 15.56em;
      }

    }

  }
}