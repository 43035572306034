.navigation {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: -1px;
  width: calc( 100vw + 2px );
  height: 100vh;
  padding: 25px;
  @include IBMPlexMono-Medium();
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  z-index: 3;
  transition: opacity .3s ease,visibility .3s ease;
  background: url(../img/menu_back_mobile.png) no-repeat 0 100%/100% 441px, url(../img/img-background-black.jpg) #27282b;

  & > * {
    width: 100%;
  }

  &.is-show{
    opacity: 1;
    visibility: visible;
  }

  li{
    margin: 67px 0;
    font-size: 38px;
    line-height: 39px;
    color: #D0D1D1;
    text-transform: uppercase;
    text-align: center;
    cursor: pointer;
    transition: color .3s ease;

    &:hover{
      color: #F92D29;
    }

  }

}
@media (min-width: $phone-landscape) {
  .navigation {

  }
}
@media (min-width: $tablet-portrait) {
  .navigation {

  }
}
@media (min-width: $tablet-landscape) {
  .navigation {
    background: url(../img/menu_back_desk.png) no-repeat 0 100%/494px 506px, url(../img/img-background-black.jpg) #27282b;

  }
}
@media (min-width: $desktop) {
  .navigation {

  }
}
