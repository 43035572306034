.video{
  @include section;
  @include is-hidden;
  min-height: auto;
  z-index: 3;

  &__layout{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

  }

  &__wrap{
    position: relative;
  }

  &__title{
    position: relative;
    font-weight: normal;
    text-align: center;
    text-transform: uppercase;
    @include IBMPlexMono-Medium();
  }

  &__play-btn{
    position: absolute;
    top: 52%;
    left: 50%;
    width: 20vh;
    height: 20vh;
    max-width: 136px;
    max-height: 136px;
    transform: translate(-50%, -50%);
    z-index: 2;
    cursor: pointer;

    svg{
      display: block;
      width: 100%;

      path {
        transition: fill .3s ease;
      }

      circle{
        fill:none;
        stroke:#fd2e2a;
        stroke-width:2px;
        transition: fill .3s ease;
      }

      path{
        fill:#fd2e2a;
        fill-rule:evenodd;
        transition: fill .3s ease;
      }

    }

    &.is-active{
      pointer-events: none;

      svg{
        transition: transform .15s ease .4s;
        transform: scale(0);
        circle{
          fill: rgb(253,46,42);
        }
        path{
          fill: #fff;
        }
      }
    }

  }

  &__obj{
    opacity: 0;
    visibility: hidden;
  }

  &__cover{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    pointer-events: none;
    opacity: 0;

    &::-webkit-media-controls-panel {
      display: none!important;
      -webkit-appearance: none;
    }

    &::-webkit-media-controls-play-button {
      display: none!important;
      -webkit-appearance: none;
    }

    &::-webkit-media-controls,
    &::-webkit-media-controls-timeline,
    &::-webkit-media-controls-enclosure {
      display:none !important;
    }

    &::-webkit-media-controls-start-playback-button {
      display: none!important;
      -webkit-appearance: none;
    }

    &.custom-video-controls{
      z-index: 2147483647;
    }

    &-canvas{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      pointer-events: none;
    }

  }

  &__skip{
    position: absolute;
    left: 50%;
    bottom: 9.6vh;
    font-size: 14px;
    color: #cbcccc;
    text-transform: uppercase;
    transform: translateX(-50%);
    cursor: pointer;
    z-index: 2;
  }

  &.is-hide{
    opacity: 0;
    visibility: hidden;
  }

  &.is-show{
    opacity: 1;
    visibility: visible;
  }

}
#video__cover-canvas{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  pointer-events: none;
}
#add-video{
  z-index: 2;
  opacity: 0;
  visibility: hidden;
  transition: opacity .3s ease, visibility .3s ease;

  .video{
    position: relative;
  }

  &.is-active{
    opacity: 1;
    visibility: visible;

    .video{
      opacity: 1;
      visibility: visible;
    }

  }

}

*::-webkit-media-controls-panel {
  display: none!important;
  -webkit-appearance: none;
}
*::-webkit-media-controls-play-button {
  display: none!important;
  -webkit-appearance: none;
}
*::-webkit-media-controls-start-playback-button {
  display: none!important;
  -webkit-appearance: none;
}
video::-webkit-media-controls {
  display:none !important;
}
video::-webkit-media-controls-start-playback-button {
  display: none !important;
}
.custom-video-controls {
  z-index: 2147483647;
}
video::-webkit-media-controls {
  opacity: 0;
}

@media (min-width: $phone-landscape) {
  .video{

  }
}
@media (max-width: $tablet-portrait - 1){
  .video{

    &__title{
      position: absolute;
      top: 18.6vh;
      left: 50%;
      width: 100%;
      font-size: 28px;
      line-height: 40px;
      transform: translateX(-50%);
      z-index: 3;
      pointer-events: none;
      white-space: pre-line;
    }

    &__obj{
      max-width: 100%;
      max-height: calc( 100vh - 162px );
      z-index: 2;
    }

    &.is-playing{
      background: unset;

      canvas, .video__cover, .video__play-btn{
        display: none;
      }

      .video__title{
        display: none;
      }

      .video__obj{
        opacity: 1;
        visibility: visible;
      }

    }

  }
}
@media (min-width: $tablet-portrait) {
  .video{

    &__layout{
      width: calc(100% - 40px);
      max-width: 943px;
      margin: 0 auto;
    }

    &__title{
      position: relative;
      margin-bottom: 6vh;
      font-size: 27px;
      line-height: 36px;
      letter-spacing: -.25px;
      z-index: 2;

      &:after{
        position: absolute;
        width: 50px;
        height: 50px;
        background-repeat: no-repeat;
        background-position: center;
        transform: translate(-7px,-8px) translateZ(0);
        content: '';
        background-image: url(../img/icon-lightning-red.svg);
      }

    }

    &__obj{
      position: relative;
      display: block;
      max-width: 100%;
      max-height: 55vh;
    }

    &__cover{
      height: 100%;
      width: 100%;
      opacity: 1;
    }

    &__play-btn {
      top: 50%;
    }

    &.is-playing{

      canvas, .video__cover, .video__play-btn{
        display: none;
      }

      .video__title{
        opacity: 0;
        visibility: hidden;
      }

      .video__obj{
        opacity: 1;
        visibility: visible;
      }

    }

  }
}
@media (max-width: 800px) and (orientation: landscape) {
  .video {

    &__title{
      top: 26vh;
      font-size: 7.2vh;
    }

  }
}
@media (max-width: 900px) and (orientation: landscape) {
  .video{

    &__layout{
      padding-top: 15vh;
    }

    &__title{
      position: absolute;
      top: 17vh;
      left: 50%;
      width: 100%;
      font-size: 4.2vh;
      line-height: 11vh;
      transform: translateX(-50%);
      z-index: 3;
      pointer-events: none;

      &:after{
        transform: translate(6px,12px) translateZ(0)
      }

    }

    &__obj{
      max-width: 100%;
      max-height: 50vh;
      margin-bottom: 6vh;
      opacity: 0;
      visibility: hidden;
      z-index: 2;
    }

    &.is-playing{
      background: unset;

      .video__title{
        display: none;
      }

      .video__obj{
        opacity: 1;
        visibility: visible;
      }

    }

  }
}
@media (min-width: $tablet-landscape) {
  .video{
    justify-content: space-between;
    left: 50%;
    margin: 0 auto;
    padding: 14vh 0 12vh;
    transform: translateX(-50%);

    &__play-btn{
      width: 96px;
      height: 96px;

      &:hover svg{
        circle{
          fill: rgb(253,46,42);
        }
        path{
          fill: #fff;
        }
      }

    }

    &__skip{
      transition: color .3s ease;

      &:hover{
        color: #fc2b2b;
      }

    }

  }
}
@media (min-width: $desktop) {
  .video{

  }
}

@media (min-width: 1920px) {
  .video{
    max-width: 9.70em;

    &__layout{
      width: calc(100% - .4em);
      max-width: 9.43em;
    }

    &__play-btn{
      max-width: .96em;
      width: .96em;
      max-height: .96em;
      height: .96em;
    }

    &__title{
      font-size: .27em;
      line-height: 1.33em;
      letter-spacing: -.009259em;

      &:after{
        width: 1.85em;
        height: 1.85em;
        transform: translate(-.259em,-0,296em) translateZ(0);
      }

    }

    &__skip{
      font-size: .16em;
    }

  }
}
