.hero{
  @include section;
  padding: 0 20px 42px;
  @include is-hidden;

  &__wrap{
    width: 192px;
    position: relative;
    pointer-events: none;
    opacity: 0;
    transition: opacity .3s ease;

    img{
      display: block;
      width: 100%;
    }

    > span{
      position: absolute;
      bottom: -117px;
      right: -173px;
      width: 460px;
      pointer-events: none;
    }

  }

}
@media (min-width: $phone-landscape) {
  .hero{

  }
}
@media (min-width: $tablet-portrait) {
  .hero{

  }
}
@media (min-width: $tablet-landscape) {
  .hero{

    &__wrap{
      width: 362px;

      > span{
        bottom: -227px;
        right: -363px;
        width: 850px;
      }

    }

  }
}
@media (min-width: $desktop) {
  .hero{

  }
}
@media (min-width: 1920px) {
  .hero{
    padding: 0 .2em .42em;

    &__wrap{
      width: 3.62em;

      > span{
        bottom: -2.57em;
        right: -3.63em;
        width: 8.5em;
      }

    }

  }
}