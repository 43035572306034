.play-audio{
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 30px;
  height: 23px;
  transform: translate(-50%, -50%) translateZ(0);
  //background: url(../img/icon-header.svg) no-repeat center;
  //background-size: cover;
  z-index: 2;
  content: '';

  &.is-playing{

  }

  svg{
    display: block;

    path{
      transition: stroke .3s ease;
    }
  }

  &:after{
    content: '';
    position: absolute;
    top: 50%;
    left: -8px;
    right: -8px;
    transform: translateY(-50%);
    border-bottom: 2px solid #ff2828;
    opacity: 0;
    z-index: 1;
    transition: opacity .3s ease;
  }

  &.is-off{

    &:after{
      opacity: 1;
    }

    svg path{
      stroke: #7a7e7f;
    }

  }

}
@media (min-width: $phone-landscape) {
  .play-audio{

  }
}
@media (min-width: $tablet-portrait) {
  .play-audio{

  }
}
@media (min-width: $tablet-landscape) {
  .play-audio{
    top: calc( 50% + 15px );
    width: 36px;
    height: 37px;
    cursor: pointer;

  }
}
@media (min-width: $desktop) {
  .play-audio{

  }
}
@media (min-width: 1920px) {
  .play-audio{
    top: calc( 50% + .15em );
    width: .36em;
    height: .37em;

    &:after{
      left: -.08em;
      right: -.08em;
      border-bottom-width: .02em;
    }

  }
}