.stars-screen{
  position: fixed;
  top: -50px;
  right: calc( 50% - 70px);
  width: 460px;
  pointer-events: none;
  content: '';
  filter: blur(10px);
  opacity: .6;
  transform: rotate(11deg);
  transition: filter .5s ease, opacity .5s ease;

  #stars-screen-canvas{
    width: 510px;
    height: 620px;
  }

  &.is-playing{
    filter: blur(0px);
    opacity: 1;
  }

  &.is-opacity{
    filter: blur(0px);
    opacity: .3;
  }

  &.is-hide{
    opacity: 0;
    visibility: hidden;
    transition: opacity .3s ease, visibility .3s ease;
  }

}
@media (min-width: $phone-landscape) {
  .stars-screen{

  }
}
@media (min-width: $tablet-portrait) {
  .stars-screen{

  }
}
@media (min-width: $tablet-landscape) {
  .stars-screen{
    top: -200px;
    right: calc( 50% - 268px);
    width: 1000px;
    height: 1000px;
    transform: rotate(4deg);

    #stars-screen-canvas{
      width: 1060px;
      height: 1060px;
    }

  }
}
@media (min-width: $desktop) {
  .stars-screen {

  }
}
@media (min-width: 1920px) {
  .stars-screen {
    top: -2em;
    right: calc( 50% - 2.68em);
    width: 10em;
    height: 10em;

    #stars-screen-canvas{
      width: 10.6em;
      height: 10.6em;
    }

  }
}