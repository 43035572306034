.btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 69px;
  padding: 0 10px;
  border: none;
  text-transform: uppercase;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;

  &--color{

    &-1{
      border: 3px solid transparent;
      background-color: #fc2b2b;
      color: $btn1-text;
      font-size: 19px;
      transition: background-color .3s ease;

      &:before{
        position: absolute;
        top: 5px;
        right: 6px;
        width: calc( 100% + 1px);
        height: inherit;
        border: 1px solid $btn1-text;
        content: '';
        pointer-events: none;
        transition: border-color .3s ease;
        background-color: transparent;
        z-index: -1;
      }

      &:active{
        background-color: $btn-color-hover;

        &:before {
          border-color: $btn-color-hover;
        }

      }

    }

  }

}
@media (min-width: $phone-landscape) {
  .btn {

  }
}
@media (min-width: $tablet-portrait) {
  .btn {

    &--color{

      &-1{
        font-size: 23px;

        &:before{
          top: 11px;
          right: 10px;
          width: calc( 100% - 4px );
          height: calc( 100% - 4px );
          border-width: 2px;
        }

      }

    }

  }
}
@media (min-width: $tablet-landscape) {
  .btn {

    &--color{

      &-1{

        &:hover{
          background-color: $btn-color-hover;

          &:before {
            border-color: $btn-color-hover;
          }

        }

      }

    }

  }
}
@media (min-width: $desktop) {
  .btn {

  }
}
@media (min-width: 1920px) {
  .btn {
    height: 3.63em;
    padding: 0 .1em;

    &--color{

      &-1{
        border-width: .157em;
        font-size: .23em;

        &:before{
          top: .263em;
          right: .315em;
          width: calc( 100% + .052em);
          border-width: .052em;
        }

      }

    }

  }
}