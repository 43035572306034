.trash{
  display: none;
}
@media (min-width: $phone-landscape) {
  .trash{

  }
}
@media (min-width: $tablet-portrait) {
  .trash{

  }
}
@media (min-width: $tablet-landscape) {
  .trash{

  }
}
@media (min-width: $desktop) {
  .trash {

  }
}
