.menu{
  font-size: 11px;
  text-transform: uppercase;

  a, span{
    display: block;
    position: relative;
    color: #727478;
    padding-right: 14px;
    cursor: pointer;

    &:after{
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      width: 10px;
      height: 13px;
      transform: translateY(-50%);
      background: url(../img/icon-next.png) no-repeat center;
      background-size: contain;
    }

    &.is-back:after{
      transform: translateY(-50%) rotate(180deg);
    }

  }

  li{
    transition: opacity .3s ease, visibility .3s ease ;

    &:not(:first-child){
      position: absolute;
      top: 0;
      right: 0;
    }

    &.is-hide{
      opacity: 0;
      visibility: hidden;
    }

  }

}
@media (min-width: $phone-landscape) {
  .menu{

  }
}
@media (min-width: $tablet-portrait) {
  .menu{

  }
}
@media (min-width: $tablet-landscape) {
  .menu{
    font-size: 17px;

    a, span{
      padding-right: 28px;
      transition: color .3s ease;

      &:hover{
        color: #000;
      }

      &:after{
        width: 16px;
        height: 18px;
      }

    }

  }
}
@media (min-width: $desktop) {
  .menu{

  }
}
@media (min-width: 1920px) {
  .menu{
    font-size: .17em;

    a, span{
      padding-right: 1.647em;

      &:after{
        width: .94em;
        height: 1.058em;
      }

    }

  }
}

.is-dark-theme,
.is-black-theme{

  .menu a{
    color: #fff;
  }

}
