.sharing-screen{
  position: relative;
  min-height: 50vh;
  padding: 13px 0 100px;
  transition: opacity .3s ease;

  &__swiper{
    width: calc( 100% - 62px );
    max-width: 313px;
    overflow: visible;

    .swiper-slide{
      width: 313px;
      height: 456px;
      padding: 119px 33px 20px;
      color: #fff;
      font-size: 21.6px;
      line-height: 33px;
      letter-spacing: -.25px;
      background: url('../img/poster.jpg') no-repeat center;
      background-size: cover;

      p{
        height: 297px;
      }

      img{
        position: absolute;
        display: block;
        width: 100%;
      }

    }

  }

  &__pagination{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 2px 22px;

    span{
      flex-grow: 1;
      width: auto;
      height: 3px;
      margin: 0 2px;
      border-radius: 0;
      background-color: #fff;
      opacity: 1;
      transition: background-color .3s ease;

      &.swiper-pagination-bullet-active{
        background-color: #fc2b2b;
      }

    }

  }

  &__btn-wrap{
    position: fixed;
    bottom: 0;
    left: 50%;
    width: 100%;
    padding: 0 23px 20px;
    transform: translateX(-50%);

    & > * {
      position: relative;
      z-index: 1;
    }

    &:before{
      content: '';
      position: absolute;
      bottom: 0;
      left: -100vw;
      width: 200vw;
      height: 168px;background: -moz-linear-gradient(top,  rgba(0,0,0,0) 0%, rgba(0,0,0,1) 84%, rgba(0,0,0,1) 93%);
      background: -webkit-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,1) 84%,rgba(0,0,0,1) 93%);
      background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,1) 84%,rgba(0,0,0,1) 93%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#000000',GradientType=0 );
      pointer-events: none;
      z-index: 1;
    }

  }

}
@media (min-width: $phone-landscape) {
  .sharing-screen{

  }
}
@media (min-width: $tablet-portrait) {
  .sharing-screen{

  }
}
@media (min-width: $tablet-landscape) {
  .sharing-screen{
    padding-top: 124px;

    &__pagination{
      max-width: 423px;
      margin: 0 auto 50px;
    }

    &__swiper{
      max-width: 423px;

      .swiper-slide{
        width: 423px;
        height: 613px;
        padding: 159px 57px 40px 47px;
        font-size: 28.6px;
        line-height: 43px;

        &:not(:only-child){
          transform: scale(.77);
          transition: transform .3s ease;
        }

        &.swiper-slide-active{
          transform: scale(1);
        }

      }

    }

    &__btn-wrap{
      max-width: 454px;
    }

  }
}
@media (min-width: $desktop) {
  .sharing-screen {

  }
}
@media (min-width: 1920px) {
  .sharing-screen {
    padding: 1.24em 0 1em;

    &__swiper{
      width: calc( 100% - .62em );
      max-width: 4.23em;

      .swiper-slide{
        width: 14.79em;
        height: 21.434em;
        padding: 5.559em 1.993em 1.399em 1.643em;
        font-size: .286em;
        line-height: 1.503em;
        letter-spacing: -.008em;

        p{
          height: 10.385em;
        }

      }

    }

    &__pagination{
      max-width: 4.23em;
      margin-bottom: .5em;

      span{
        height: .03em;

      }

    }

    &__btn-wrap{
      padding: 0 .23em .2em;

      &:before{
        height: 1.68em;
      }

    }

  }
}
