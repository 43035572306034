.site, .site--popup{
  position: relative;
  min-width: 320px;
  min-height: 100%;
  background: url(../img/img-background.jpg) $background-color;
  @include FfiraCode-Medium;
  overflow: hidden;
  transition: background .5s ease-out;

  &:after{
    content: '';
    position: fixed;
    top: -50vw;
    left: 0;
    bottom: -50vw;
    width: 100%;
    background: url(../img/img-background-black.jpg) #27282b;
    opacity: 0;
    pointer-events: none;
    transition: opacity .3s ease;
    transform: translateZ(0);
  }

  img, svg{
    pointer-events: none;
  }

  & > * {
    position: relative;
    width: 100%;
    z-index: 2;
  }

  &__header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    left: 50%;
    width: 100%;
    margin: 0 auto;
    padding: 31px 12px;
    @include FfiraCode-Medium;
    transform: translateX(-50%) translateZ(0);
    transition: opacity .5s ease, visibility .5s ease;
    z-index: 4;

    > * {
      position: relative;
      z-index: 3;
    }

    &.is-light{

      &:after{
        content: '';
        position: absolute;
        top: 0;
        left: -100vw;
        width: 200vw;
        height: 168px;
        background: -moz-linear-gradient(top,  $background-color 7%, $background-color 16%, rgba(0,0,0,0) 100%);
        background: -webkit-linear-gradient(top,  $background-color 7%,$background-color 16%,rgba(0,0,0,0) 100%);
        background: linear-gradient(to bottom,  $background-color 7%,$background-color 16%,rgba(0,0,0,0) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#00000000',GradientType=0 );
        pointer-events: none;
        z-index: 1;
      }

    }

    &.is-dark,
    &.is-menu-open{

      .logo{
        color: #fff;
      }

      .hamburger hr{
        background-color: #fff;
      }

    }

    &.is-dark{

      &:after{
        content: '';
        position: absolute;
        top: 0;
        left: -100vw;
        width: 200vw;
        height: 168px;
        background: -moz-linear-gradient(top,  rgba(0,0,0,1) 7%, rgba(0,0,0,1) 16%, rgba(0,0,0,0) 100%);
        background: -webkit-linear-gradient(top,  rgba(0,0,0,1) 7%,rgba(0,0,0,1) 16%,rgba(0,0,0,0) 100%);
        background: linear-gradient(to bottom,  rgba(0,0,0,1) 7%,rgba(0,0,0,1) 16%,rgba(0,0,0,0) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#00000000',GradientType=0 );
        pointer-events: none;
        z-index: 1;
      }

    }

    &.is-hide{
      opacity: 0;
      visibility: hidden;
    }

    &.is-on-video{

      .logo{
        color: #fff;
      }

      .hamburger hr{
        background-color: #fff;
      }

    }

  }

  &__content{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    min-height: $min-height;
    user-select: none;
  }

  &__carriage{
    position: fixed;
    top: 0;
    right: 4px;
    width: 2px;
    background-color: #fc2b2b;
    pointer-events: none;
    z-index: 999;
  }

  &__wrap{
    transition: opacity .3s ease;

    &.is-hide{
      opacity: 0;
    }

  }

  &__star{

    &:before{
      position: absolute;
      top: 0;
      right: calc( 50% - 153px);
      width: 460px;
      height: 460px;
      background: url(../img/img-star.png) no-repeat;
      background-size: cover;
      transform: rotate(11deg);
      pointer-events: none;
      content: '';
      filter: blur(10px);
      opacity: .6;
      transition: opacity .3s ease;
      z-index: 1;
    }

  }

  .is-show{
    opacity: 1;
    visibility: visible;
  }

  &.is-dark-theme,
  &.is-black-theme,
  &.is-black-theme-1{

    &:after{
      opacity: 1;
    }

  }

}
@media (min-width: $phone-landscape) {
  .site{

  }
}
@media (min-width: $tablet-portrait) {
  .site{

  }
}
@media (min-width: $tablet-landscape) {
  .site, .site--popup{

    &__header{
      max-width: 927px;
      padding-top: 42px;
      padding-bottom: 20px;

      &.is-on-video,
      &.is-dark{

        .menu{
          a, span {
            &:hover {
              color: #fff;
            }
          }
        }

      }

      &.is-light{

        .menu{
          a, span {
            &:hover {
              color: #000;
            }
          }
        }

      }

      &--shared{

        p{

          font-size: 32px;
          line-height: 34px;
        }

      }

    }

    &__content{

    }

    &__star {

      &:before {
        top: -200px;
        right: calc( 50% - 268px);
        width: 1000px;
        height: 1000px;
        transform: rotate(4deg);
      }

    }

  }
}
@media (min-width: $desktop) {
  .site, .site--popup{

    &__header{
      max-width: 1180px;
      padding-top: 25px;
    }

    &:before{
      content: '';
      position: fixed;
      right: -1px;
      bottom: -8px;
      width: 550px;
      height: 696px;
      background: url(../img/img-background-desktop.png) no-repeat;
      background-size: cover;
      opacity: 0;
      transition: opacity .3s ease;
      z-index: 1;
    }

    &.is-dark-theme,
    &.is-black-theme,
    &.is-black-theme-1{

      &:before{
        background-image: url(../img/revo-kaif_text-bg.svg);
        background-size: auto;
      }

    }

    &.is-add-slogan:before{
      opacity: 1;
    }

    &.is-blur-back:before{
      filter: blur(10px);
      transition: filter .3s ease;
    }

  }
}

@media (min-width: 1250px) {
  .site, .site--popup {

    &__header {
      padding-top: 53px;
    }
  }
}

@media (min-width: 1920px) {
  .site, .site--popup{

    &__header{
      max-width: 11.8em;
      padding: .53em .19em .2em;

      &--shared{

        p{
          font-size: .32em;
          line-height: 1.06em;
        }

      }

    }

    &__star {

      &:before {
        top: -2em;
        right: calc( 50% - 2.68em);
        width: 10em;
        height: 10em;
      }

    }

    &.is-dark-theme,
    &.is-black-theme,
    &.is-black-theme-1{

      &:before{
        background-image: url(../img/revo-kaif_text-bg.svg);
        background-size: auto;
      }

    }

    &.is-add-slogan:before{
      opacity: 1;
    }

    &.is-blur-back:before{
      filter: blur(.1em);
      transition: filter .3s ease;
    }

  }
}
