.sharing-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  margin: 0 auto 11px;
  padding: 0 19px;
  @include FfiraCode-Medium;
  transform: translateZ(0);
  z-index: 4;

  &:after{
    position: absolute;
    top: 100%;
    right: -16px;
    width: 69px;
    height: 97px;
    background: url(../img/icon-arrow-down.svg) no-repeat center;
    background-size: contain;
    pointer-events: none;
    content: '';
  }

  > * {
    position: relative;
    z-index: 2;
  }

  .logo{
    top: 0;
    max-width: 85px;
    margin-bottom: 15px;
    color: #fff;
    font-size: 33px;

    img{
      top: calc( 50% + 2px);
      left: calc( 50% + 33px);
      max-width: 57px;
    }

  }

  p{
    color: transparent;
    font-size: 20px;
    text-transform: uppercase;
    -webkit-text-stroke: .5px #fc2b2b;
  }

}
@media (min-width: $phone-landscape) {
  .sharing-header{

    p{
      left: 13px;
      font-size: 25px;
    }

  }
}
@media (min-width: $tablet-portrait) {
  .sharing-header{

  }
}
@media (min-width: $tablet-landscape) {
  .sharing-header{

  }
}
@media (min-width: $desktop) {
  .sharing-heade{

  }
}
@media (min-width: 1920px) {
  .sharing-header{
    margin-bottom: .11em;
    padding: 0 .19em;

    &:after{
      right: -.16em;
      width: .69em;
      height: .97em;
    }

    .logo{
      max-width: 2.576em;
      margin-bottom: .455em;
      font-size: .33em;

      img{
        top: calc( 50% + .061em);
        left: calc( 50% + 1em);
        max-width: 1.727em;
      }

    }

    p{
      font-size: .2em;
    }
  }
}
