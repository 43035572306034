.first-contact{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 119px 0 42px;
  @include is-hidden;

  &__layout{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }

  &__head{
    position: relative;
    top: 40px;
    width: 100%;
    max-width: 375px;
    opacity: 0;
    transition: all .3s ease, opacity .3s ease .1s;
  }

  &__topic{
    position: relative;
    margin: 0 18px;
    font-size: 27px;
    line-height: 36px;
    letter-spacing: -.25px;
    text-transform: uppercase;
    opacity: 0;
    transition: opacity .3s ease .3s;
    z-index: 3;

    li{
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      opacity: 0;

      &:after{
        position: absolute;
        width: 110px;
        height: 110px;
        background: url('../img/icon-boom-black.svg') no-repeat center;
        background-size: contain;
        transform: translate(-32px, -36px);
        opacity: 0;
        content: '';
      }

      &.is-active{
        opacity: 1;
        transition: opacity .3s ease .3s;

        &:after{
          opacity: 1;
          transition: opacity .3s ease .5s;
        }

      }

    }

  }

  &__pagination{
      display: flex;
      align-items: flex-start;
      position: relative;
      min-height: 115px;
      margin-bottom: 10px;
      color: #fff;
      @include Miratrix-Normal;
      font-size: 22px;
      letter-spacing: 1.5px;;
      line-height: .6;
      transition: all .3s ease;

      li {
        position: relative;
        pointer-events: none;
        transition: all .3s ease;

        &:not(:last-child){
          margin-right: 28px;
        }

        &.is-passed{
          pointer-events: all;
          cursor: pointer;
        }

        &.is-current{
          font-size: 192px;
          pointer-events: none;
        }

        &:nth-child(1){
          margin-left: 18px;

          &.is-current{
            margin-left: -62px;
          }

        }
        &:nth-child(2){

          &.is-current{
            margin: 0 -15px  0 -50px;
          }

        }
        &:nth-child(3){

          &.is-current{
            margin-left: -8px;
          }

        }

      }

    }

  &__body{
    display: flex;
    position: relative;
    top: 40px;
    width: 100%;
    height: 125px;
    //box-sizing: content-box;
    opacity: 0;
    transition: top .3s ease .3s, opacity .3s ease .4s;
  }

  &__wrap{
    display: flex;
    justify-content: center;
    align-items: flex-end;
    position: absolute;
    top: 50%;
    left: 50%;
    //flex-shrink: 0;
    width: 100%;
    padding: 0 39px;
    opacity: 0;
    transition: opacity .3s ease;
    transform: translate(-50%, -50% ) translateZ(0);
    pointer-events: none;

    &.slide--company{

      .first-contact__btn-wrap li {
        &:nth-child(1) span {
          img, svg{max-height: 25px;}
        }
        &:nth-child(2) span {
          img, svg{max-height: 85px;}
        }
      }

    }

    &.is-active{
      opacity: 1;
      pointer-events: all;

      .first-contact__btn-wrap span{

        img, svg{
          animation: .3s linear 0s 1 normal both running scale-easeOutElastic;
        }

      }

    }

  }

  &__btn-wrap{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 339px;
    max-height: 125px;
    text-align: center;

    li{
      display: flex;
      align-items: center;
      width: 35%;

      &:nth-child(1){
        justify-content: flex-end;

        span{

          &:after{
            top: 43%;
            left: 48%;
            width: 150px;
            height: 129px;
            background: url(../img/img-btn-border.png) no-repeat center;
            background-size: cover;
          }

          &.is-active:after{
            background-image: url(../img/img-btn-border-1.png);
          }

        }

      }
      &:nth-child(2){
        justify-content: flex-start;

        span{

          &:after{
            top: 50%;
            left: 50%;
            width: inherit;
            height: inherit;
            border: 2px solid #202124;
            border-radius: 50%;
          }

          &.is-active:after{
            background-color: #202124;
          }

        }

      }

    }

    span{
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      width: 125px;
      height: 125px;
      cursor: pointer;

      &:after{
        content: '';
        position: absolute;
        transform: translate(-50%,-50%);
        pointer-events: none;
      }

      img, svg{
        display: block;
        position: relative;
        z-index: 1;
        max-height: 55px;
        width: 100%;
        transform: translateZ(0);

        .i1-st0{fill:#D3D4D4;}
        .i1-st1{fill:#FD2E2A;}
        .i1-st2{fill:none;stroke:#FD2E2A;stroke-miterlimit:10;}

        .i2-st0{fill:#D3D4D4;stroke:#FD2E2A;stroke-width:2;stroke-miterlimit:10;}

        .i3-st0{fill:#FD2E2A;}

        .i4-st0{fill:#FFFFFF;}

      }

      &.is-active{

        img, svg{

          .i1-st0{fill:#FD2E2A;}
          .i1-st1{fill:#202124;}
          .i1-st2{stroke:#202124}

          .i2-st0{fill:#202124;stroke:#FD2E2A}

          .i3-st0{fill:#202124;}

          .i4-st0{fill:#FD2E2A;}

        }

      }

    }

  }

  &.is-show{
    .first-contact__body{
      top: 0;
      opacity: 1;
    }
    .first-contact__head{
      top: 0;
      opacity: 1;
    }
    .first-contact__topic{
      opacity: 1;
    }
  }

}
@media (min-width: $phone-landscape) {
  .first-contact{

  }
}
@media (min-width: $tablet-portrait) {
  .first-contact{

  }
}
@media (min-width: $tablet-landscape) {
  .first-contact{
    padding: 129px 0 42px;

    &__layout{
      justify-content: space-around;
    }

    &__head{
      position: relative;
      max-width: 1135px;
      margin-bottom: 112px;
    }

    &__topic{
      position: absolute;
      left: 293px;
      bottom: 0;
      width: 300px;

      max-width: 557px;
      margin: 0 auto;
      font-size: 41px;
      line-height: 53px;

      li{

        &:nth-child(1){
          display: block;
        }

        &:after{
          width: 40px;
          height: 70px;
          transform: translate(28px, -10px);
          background-image: url('../img/icon-lightning-black.svg');
        }

      }

    }

    &__body{
      height: 170px;
    }

    &__pagination{
      min-height: 147px;
      margin-bottom: 9px;
      font-size: 30px;

      li{

        &:not(:last-child){
          margin-right: 95px;
        }

        &.is-current{
          font-size: 245px;
        }

        &.is-passed:hover{
          color: #FD2E2A;
        }

        &:nth-child(1){
          margin-left: -2px;

          &.is-current{
            margin-right: 62px;
          }

          & + li {
            margin-right: 53px;
          }

        }
        &:nth-child(2){

          &.is-current{
            margin: 0 64px  0 -28px;
          }

        }
        &:nth-child(3){

          &.is-current{
            margin-left: 18px;
            letter-spacing: 21px;
          }

        }

      }

    }

    &__btn-wrap{
      max-width: 454px;
      max-height: 190px;
      margin-right: 8px;

      li{

        span:after{
          transition: color .3s ease, border-color .3s ease, background-color .3s ease;
        }

        &:nth-child(1){
          span {
            &:after {
              top: 43%;
              left: 33%;
              width: 207px;
              height: 177px;
            }

            &:before{
              background-image: url(../img/img-btn-border-1-hover.png)
            }

            &:hover:after{
              background-image: url(../img/img-btn-border-1-hover.png);
              transition: background-image .3s ease;
            }

          }
        }
        &:nth-child(2){
          span {
            &:after {
              border-width: 3px;
            }

            &:hover:after{
              border-color: #737373;
              background-color: #737373;
            }

          }
        }

        span:hover{

          svg .i3-st0{
            fill: #fff;
            transition: fill .3s ease;
          }

        }

      }

      span{
        width: 170px;
        height: 170px;
      }

    }

    &__wrap{

      &.slide--sex{

        .first-contact__btn-wrap li {
          &:nth-child(1) span {
            img, svg{
              max-height: 73px;
              left: -26px;
            }
          }
          &:nth-child(2) span {
            img, svg{
              max-height: 80px;
            }
          }
        }

      }

      &.slide--company{

        .first-contact__btn-wrap li {
          &:nth-child(1) span {
            img, svg{
              max-height: 20px;
              top: 4px;
              left: -25px;
            }
          }
          &:nth-child(2) span {
            img, svg{
              top: -5px;
              max-height: 99px;
            }
          }
        }

      }

    }

  }
}
@media (max-width: 902px) and (orientation: landscape) {
  .first-contact{
    padding: 20vh 0 4vh;

    &__topic{
      font-size: 5.8vh;
      line-height: 11vh;
    }

    &__pagination{
      min-height: 10vh;

      li {

        &.is-current {
          font-size: 30vh;
        }

      }

    }

  }
}
@media (min-width: $desktop) {
  .first-contact{

  }
}
@media (min-width: 1440px) {
  .first-contact{

    &__pagination{

      li{

        &:nth-child(1){

          &.is-current {
            margin-left: -170px;
          }

        }

      }

    }

  }
}
@media (min-width: 1920px) {
  padding: 1.29em 0 .42em;

  &__head{
    top: .4em;
    max-width: 11.35em;
    margin-bottom: 1.12em;
  }

  &__topic{
    left: 7.146em;
    width: 7.317em;

    max-width: 13.585em;
    font-size: .41em;
    line-height: 1.293em;
    letter-spacing: -.006em;

    li{

      &:after{
        width: .976em;
        height: 1.707em;
        transform: translate(.683em, -.244em);
      }

    }

  }

  &__pagination{
    min-height: 4.9em;
    margin-bottom: .3em;
    font-size: .3em;
    letter-spacing: .05em;

    li {

      &:not(:last-child){
        margin-right: 3.167em;
      }

      &.is-current{
        font-size: 8.167em;
      }

      &.is-current{
        font-size: 6.4em;
      }

      &:nth-child(1){
        margin-left: -.067em;

        &.is-current{
          margin-left: -5.667em;
        }

        & + li {
          margin-right: 1.767em;
        }

      }
      &:nth-child(2){

        &.is-current{
          margin: 0 2.133em  0 -.933em;
        }

      }
      &:nth-child(3){

        &.is-current{
          margin-left: .6em;
          letter-spacing: .7em;
        }

      }

    }

  }

  &__body{
    top: .4em;
    height: 1.7em;
  }

  &__wrap{
    padding: 0 .39em;

    &.slide--sex{

      .first-contact__btn-wrap li {
        &:nth-child(1) span {
          img, svg{
            max-height: .73em;
            left: -.26em;
          }
        }
        &:nth-child(2) span {
          img, svg{
            max-height: .8em;
          }
        }
      }

    }

    &.slide--company{

      .first-contact__btn-wrap li {
        &:nth-child(1) span {
          img, svg{
            max-height: .2em;
            top: .04em;
            left: -.25em;
          }
        }
        &:nth-child(2) span {
          img, svg{
            top: -.05em;
            max-height: .99em;
          }
        }
      }

    }

  }

  &__btn-wrap{
    max-width: 4.54em;
    max-height: 1.9em;
    margin-right: .08em;

    li{

      &:nth-child(1){
        span {
          &:after {
            width: 2.07em;
            height: 1.77em;
          }

        }
      }
      &:nth-child(2){
        span {
          &:after {
            border-width: .03em;
          }
        }
      }

    }

    span{
      width: 1.7em;
      height: 1.7em;

      img, svg{
        max-height: .55em;
      }

    }

  }

}
