.intro{
  @include section;
  flex-direction: column;
  padding: 0 20px 42px;
  @include is-hidden;
  min-height: auto;

  &__content{
    width: 100%;
    text-transform: uppercase;

    p:not(:first-child){
      margin-top: 10vh;
    }

  }

  &__layout{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: calc( 100% - 20px );
  }

  &__skip{
    color: #737373;
    text-transform: uppercase;
    cursor: pointer;
  }

}
@media (min-width: $phone-landscape) {
  .intro{

  }
}
@media (min-width: $tablet-portrait) {
  .intro{

    &__content{
      max-width: 580px;

      br{
        display: none;
      }

    }

  }
}
@media (max-width: 902px) and (orientation: landscape) {
  .intro{

    &__content{
      font-size: 7.2vh;
      line-height: 11vh;
    }

  }
}
@media (min-width: $tablet-landscape) {
  .intro{
    left: 50%;
    max-width: 970px;
    margin: 0 auto;
    transform: translateX(-50%);

    &__skip{
      transition: color .3s ease;

      &:hover{
        color: #000;
      }

    }

  }
}
@media (min-width: $desktop) {
  .intro{

  }
}
@media (min-width: 1920px) {
  .intro{
    max-width: 9.7em;
    padding: 0 .2em .42em;

    &__layout{
      height: calc( 100% - .2em );
    }

    &__content {
      max-width: 19.33em;
    }

    &__skip{
      font-size: .16em;
    }

  }
}
