.disclaimer-text{
  min-height: 100vh;
  padding: 130px 20px 50px;

  &__wrap{
    text-transform: uppercase;

    p {

      span {
        color: #fc2b2b;

      }

    }

  }

}
@media (min-width: $phone-landscape) {
  .disclaimer-text{

  }
}
@media (min-width: $tablet-portrait) {
  .disclaimer-text{

  }
}
@media (min-width: $tablet-landscape) {
  .disclaimer-text{
    max-width: 970px;
    margin: 0 auto;
    padding-top: 175px;

  }
}
@media (min-width: $desktop) {
  .disclaimer-text {

  }
}
@media (min-width: 1920px) {
  .disclaimer-text {
    max-width: 9.7em;
    padding: 1.75em .2em .5em;
  }
}
