.hamburger {
  position: absolute;
  top: -2px;
  right: 0;
  display: block;
  width: 29px;
  height: 14px;
  z-index: 3;
  transform: translate(0, -50%);
  cursor: pointer;

  li{
    cursor: pointer;
  }

  hr {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    margin: 0;
    border: none;
    background-color: #727478;
    border-radius: 2px;
    opacity: 1;
    left: 0;
    transition: all .3s ease;
    transform: translateZ(0);

    &:nth-child(1) {
      top: 0;
    }

    &:nth-child(2),
    &:nth-child(3) {
      top: calc( 50% - 1px );
    }

    &:nth-child(4) {
      top: calc( 100% - 2px );
    }

  }

  &.is-active {
    top: 3px;
    right: 4px;
    transform: translate(-50%, -50%);

    hr{
      width: 160%;
      background-color: #F92D29!important;

      &:nth-child(1) {
        top: calc( 50% - 1px );
        width: 0;
        left: 50%;
      }

      &:nth-child(2) {
        transform: rotate(45deg) translateZ(0);
      }

      &:nth-child(3) {
        transform: rotate(-45deg) translateZ(0);
      }

      &:nth-child(4) {
        bottom: calc( 50% - 1px );
        width: 0;
        left: 50%;
      }

    }

  }

  &.is-hide{
    display: none;
  }

}
@media (min-width: $phone-landscape) {
  .hamburger {

  }
}
@media (min-width: $tablet-portrait) {
  .hamburger {

  }
}
@media (min-width: $tablet-landscape) {
  .hamburger {
    top: 3px;
  }
}
@media (min-width: $desktop) {
  .hamburger {

  }
}
