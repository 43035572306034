.sharing-text{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 100px 0 100px;

  &__wrap{
    position: relative;
    max-width: 1014px;
    margin: 0 auto;
    padding: 20px 22px 70px;
    text-transform: uppercase;

    &:before{
      position: absolute;
      bottom: -30px;
      left: -230px;
      width: 540px;
      height: 220px;
      background: url(../img/img-star.png) no-repeat;
      background-size: 100% 100%;
      pointer-events: none;
      content: '';
      filter: blur(10px);
      opacity: .6;
      z-index: 1;
    }

    &:after{
      content: '';
      position: absolute;
      right: -62px;
      bottom: -38px;
      width: 200px;
      height: 200px;
      background: url(../img/icon-boom.svg) no-repeat center;
      background-size: contain;
      pointer-events: none;
      opacity: .9;
    }

    p:last-of-type{

      &:after{
        content: '';
        position: relative;
        top: 5px;
        left: 10px;
        display: inline-block;
        width: 28px;
        height: 28px;
        background: url(../img/icon-smile-transparent.png) center no-repeat;
        background-size: contain;
        pointer-events: none;
      }

    }

  }

}
@media (min-width: $phone-landscape) {
  .sharing-text{

  }
}
@media (min-width: $tablet-portrait) {
  .sharing-text{

  }
}
@media (min-width: $tablet-landscape) {
  .sharing-text{
    padding-top: 125px;

  }
}
@media (min-width: $desktop) {
  .sharing-text{

  }
}
@media (min-width: 1920px) {
  .sharing-text{
    padding: 1.25em 0 1em;

    &__wrap{
      max-width: 10.14em;
      padding: .2em .22em .7em;

      &:before{
        bottom: -.3em;
        left: -2.3em;
        width: 5.4em;
        height: 2.2em;
        filter: blur(.1em);
      }

      &:after{
        right: -.62em;
        bottom: -.38em;
        width: 2em;
        height: 2em;
      }

      p:last-of-type{

        &:after{
          top: .05em;
          left: .1em;
          width: .28em;
          height: .28em;
        }

      }

    }
  }
}
