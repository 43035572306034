.age-control{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 43px auto;
  max-width: 339px;
  max-height: 125px;
  text-align: center;

  li{
    display: flex;
    align-items: center;
    position: relative;

    &:nth-child(1),
    &:nth-child(3){
      width: 36.87%;
    }

    &:nth-child(1){
      justify-content: flex-end;

      .age-control__btn{
        border-color: #fc3131;
        color: #fc3131;

        &.is-active{
          background-color: #fc3131;
          color: #202124;
        }

      }

    }
    &:nth-child(2){
      width: calc(100% - 250px);
      min-width: 67px;
      justify-content: center
    }
    &:nth-child(3){
      justify-content: flex-start
    }

  }

  &__btn{
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 125px;
    height: 125px;
    padding-bottom: 5px;
    border: 2px solid #202124;
    border-radius: 50%;
    font-size: 45px;
    cursor: pointer;

    &.is-active{
      background-color: #202124;
      color: #fc3131;
    }

  }

  &__text{
    color: #fc3131;
    font-size: 40px;
  }

}
@media (min-width: $phone-landscape) {
  .age-control{

  }
}
@media (min-width: $tablet-portrait) {
  .age-control{

  }
}
@media (min-width: $tablet-landscape) {
  .age-control{
    margin: 59px auto;

    li{

      &:nth-child(1){

        .age-control__btn:hover{
          border-color: transparent;
          background-color: #f54c4c;
          color: #4a4d4f;
        }

      }
      &:nth-child(2){
        min-width: 119px;

      }
      &:nth-child(3){

      }

    }

    &__text{
      font-size: 47px;
    }

    &__btn{
      width: 170px;
      height: 170px;
      border-width: 3px;
      font-size: 57px;
      transition: color .3s ease, border-color .3s ease, background-color .3s ease;

      &:hover{
        border-color: #737373;
        background-color: #737373;
        color: #f54c4c;
      }

    }

  }
}
@media (min-width: $desktop) {
  .age-control{

  }
}
//@media (min-width: 1920px) {
//  .age-control{
//    max-width: 3.39em;
//    max-height: 1.25em;
//    margin: .59em auto;
//
//    li{
//      &:nth-child(2){
//        width: calc(100% - 2.5em);
//        min-width: 1.19em;
//      }
//
//    }
//
//    &__btn{
//      width: 2.982em;
//      height: 2.982em;
//      border-width: .053em;
//      padding-bottom: .053em;
//      font-size: .57em;
//    }
//
//    &__text{
//      font-size: .4em;
//    }
//
//  }
//}