.notice{
  @include section;
  flex-direction: column;
  padding: 0 20px 1vh;
  @include is-hidden;

  &__content{
    width: 100%;
    text-transform: uppercase;

  }

  &__layout{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: calc( 100% - 20px );
  }

}
@media (min-width: $phone-landscape) {
  .notice{

  }
}
@media (max-width: 902px) and (orientation: landscape) {
  .notice{

    &__content{
      font-size: 7.2vh;
      line-height: 11vh;
    }

  }
}
@media (min-width: $tablet-portrait) {
  .notice{

    &__content{
      max-width: 560px;

      br{
        display: none;
      }

    }

  }
}
@media (min-width: $tablet-landscape) {
  .notice{
    left: 50%;
    max-width: 970px;
    margin: 0 auto;
    transform: translateX(-50%);

  }
}
@media (min-width: $desktop) {
  .notice {

  }
}
@media (min-width: 1920px) {
  .notice {
    max-width: 9.7em;
    padding: 0 .2em 1vh;

    &__content{
      max-width: 18.66em;
    }

    &__layout{
      height: calc( 100% - .2em );
    }

  }
}